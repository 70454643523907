import side from "../../assets/side.png";
import {useEffect, useState} from "preact/hooks";

const Side = () => {

    const [height, setHeight] = useState(null);

    useEffect(() => {
        setHeight(window.innerHeight);
    }, []);

    return(
        <aside className="vector" style={{minHeight: height}}>
            <img src={side} alt="cangimar"/>
        </aside>
    );

};

export default Side;
