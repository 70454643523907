import {Component} from 'preact';

import Home from '../routes/home';
import Side from "../routes/home/side";

export default class App extends Component {

	render() {
        return (
            <div id="app">
                <Home/>
                <Side/>
            </div>
        );
    }
}
